import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CSectTitle03,
  CInfinitySlider03,
  COverflowTable,
  CSimpleCard,
  CTable,
  CBtnList,
  CInlineLinkList,
  LVenue,
  CFixedImg,
  LBanquetContact,
} from '../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: (
              <>
                小宴会場 <br className="u_sp" />
                霞・彩・宴
              </>
            ),
            sub: <>KASUMI・AYA・UTAGE</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/kasumi_aya_utage/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/kasumi_aya_utage/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="u_pt50 u_mbLarge">
        <LWrap>
          <CInfinitySlider03
            data={[
              {
                img: {
                  src: '/assets/images/banquet/kasumi_aya_utage/img_banquet.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/kasumi_aya_utage/img_banquet02.png',
                  alt: '',
                },
              },
            ]}
          />
          <p className="u_mbMedium">
            親しいご友人とのお集まりや趣味の会など。内輪のご会合、お打合せに幅広くご活用いただけます。
          </p>
          <COverflowTable>
            <table className="c_fixedTable">
              <thead>
                <tr>
                  <th rowSpan={2}>スタイル</th>
                  <th colSpan={4}>ご人数</th>
                  <th rowSpan={2}>広さ(m²)</th>
                  <th rowSpan={2}>天井高</th>
                </tr>
                <tr>
                  <th>正餐</th>
                  <th>立食</th>
                  <th>スクール</th>
                  <th>シアター</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>霞</th>
                  <td>20</td>
                  <td>-</td>
                  <td>20</td>
                  <td>45</td>
                  <td>48</td>
                  <td>2.7</td>
                </tr>
                <tr>
                  <th>彩</th>
                  <td>20</td>
                  <td>-</td>
                  <td>20</td>
                  <td>45</td>
                  <td>48</td>
                  <td>2.7</td>
                </tr>
                <tr>
                  <th>宴</th>
                  <td>20</td>
                  <td>-</td>
                  <td>20</td>
                  <td>45</td>
                  <td>48</td>
                  <td>2.7</td>
                </tr>
              </tbody>
            </table>
          </COverflowTable>
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <LWrap>
          <CSectTitle03 title="レイアウト例" />
          <CFixedImg
            width={680}
            img={{
              src: '/assets/images/banquet/kasumi_aya_utage/img_example.png',
              alt: '',
            }}
            exClass="u_mb15"
          />
        </LWrap>
      </section>
      <section>
        <LWrap>
          <CBtnList
            data={[
              {
                label: 'CO₂ゼロ MICE®のご案内',
                size: 'large',
                link: {
                  href: '/banquet/co2zero_mice/',
                },
                color: 'borderBlack',
              },
            ]}
          />
          <CInlineLinkList
            exClass="u_mt30"
            data={[
              {
                label: 'テレビ会議システムのご案内',
                link: {
                  href: '/assets/files/pdf/ContentsDistributionSystem.pdf',
                  blank: true,
                },
                icon: 'document',
              },
            ]}
          />
        </LWrap>
      </section>
      <LVenue />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
